<template>
  <div class="header">

   <div class="ctitle">
     <div class="loginmain">
         <img class="logoimg" src="../../../assets/images/logo.png"/>
     </div>
     <router-link :to="{ path: '/home' }">
       <a href="index.vue" target="_blank" align="right">返回首页></a>
     </router-link>
   </div>
  </div>
</template>
<script>
  export default {
		data() {
			return {

			}
		},
		methods: {

		},
	}
</script>

<style lang="less" scoped>

  .header{
    width: 100%;
    height: 100px;
    position: relative;
    // background-color: #f0f0f0;
  }
  .ctitle{
    height: 90px;
    width: 90%;
    margin: auto;
    font-size: 14px;
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div{
      font-size: 30px;
      color: #000;
    }
    a{
      margin-right: 20px;
      color: #000;
      font-size: 16px;
    }
  }
  .loginmain{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logoimg {
     width: 280px;
         height: 70px;
      // border: 2px solid #bde5f9;
      // border-radius: 50%;
      // padding: 4px;

      // img {
      //   width: 36px;
      //   height: 36px;
      //   border: 2px solid #fff;
      //   padding: 4px;
      //   border-radius: 50%;

      // }
    }
    // .logoimg {
    //   margin-top: 5px;
    //   border: 2px solid #bde5f9;
    //   border-radius: 50%;
    //   padding: 4px;
    //   img {
    //     width: 36px;
    //     height: 36px;
    //      border: 2px solid #fff;
    //      padding: 4px;
    //      border-radius: 50%;
    //   }
    // }
    // .centerdiv {
    //   font-size: 28px;
    //   font-weight: bold;
    //   color: #306dac;
    //   padding-left: 10px;
    // }
  }
</style>
