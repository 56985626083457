<template>
  <div class="xa_foot">
  	<div class="main">
  		<p>Copyright&nbsp;©&nbsp;2023&nbsp;&nbsp;&nbsp;&nbsp; 航空科技成果线上展厅所有&nbsp;&nbsp;&nbsp;&nbsp;</p>
  		<p>技术支持：武汉迪科巨图科技有限公司</p>
  	</div>
  </div>
</template>

<script>

export default {
  data() {
    return {

     }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  },

}
</script>

<style lang="less" scoped>
  .xa_foot {
      background: #fff;
      padding-top: 20px;
      position: fixed;
      bottom: 0px;
      left: 0px;
      width: 100%;
  }
  .xa_foot * {
      color: #000 !important;
      text-align: center !important;
  }
  .main {
      width: 1180px;
      margin: auto;
      font-size: 12px;
      text-align: left;
  }
  .xa_foot p {
      font-size: 14px;
      line-height: 30px;
      color: #999;
      text-align: left;
  }

</style>
