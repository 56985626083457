<template>
  <div class="login">
   <hNav/>
    <div class="content">

    <div class="main">
    <div class="mylogin" align="center">
      <h4>注册</h4>
      <el-form :model="loginForm" :rules="refForm" ref="refForm" label-width="0px">
        <el-form-item label="" prop="username" style="margin-top: 10px">
             <el-input class="inps" minlength='6' maxlength='15' placeholder="设置您的用户名" v-model="loginForm.username"></el-input>
        </el-form-item>
        <el-form-item label="" prop="password" style="margin-top: 10px">
             <el-input class="inps" type='password' placeholder="设置您的登陆密码" v-model="loginForm.password"></el-input>
        </el-form-item>
        <el-form-item label="" prop="secondPassword" style="margin-top: 10px">
             <el-input class="inps" type='password' placeholder="请再次输入您的登陆密码" v-model="loginForm.secondPassword"></el-input>
        </el-form-item>
        <el-form-item label="" prop="mobile" style="margin-top: 10px">
             <el-input class="inps" placeholder="请输入你的手机号码" v-model="loginForm.mobile" maxlength='11'></el-input>
        </el-form-item>

        <el-form-item prop="code"  class="checkCode">
          <el-row>
            <el-col :span="15">
              <el-input
                class="inps"
                clearable
                v-model="loginForm.code"
                placeholder="输入验证码"
              ></el-input>
            </el-col>
            <el-col :span="6">
             <el-button
               @click.stop="sendVerificationCode"
               type="primary"
               style="margin-left: 10px"
               v-if="show"
               >发送验证码</el-button
             >
             <el-button
               type="primary"
               style="margin-left: 10px"
               v-if="!show"
               disabled
               >{{ count }}秒后重发</el-button
             >
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item style="margin-top: 55px">
          <el-button type="primary" round class="submitBtn" @click="submitForm">注册
          </el-button>
        </el-form-item>
        <div class="unlogin" @click="tologin">

          已有账号?去登陆
          <!-- |
          <router-link :to="{ path: '/forgetpwd' }"> 隐私保护政策 </router-link> -->
        </div>
      </el-form>
    </div>
    </div>
    </div>
    <bNav/>
  </div>
</template>


<script>
  import hNav from './components/hnav.vue'
  import bNav from './components/bnav.vue'
  import api from '@/config/api'
  import {
    getRequst,
    postRequest
  } from '@/api/index'

  export default {
    components: {
      hNav,
      bNav,
    },
    name: "Login",
    data: function() {
      return {
        loginForm: {
          username: "",
          mobile: "",
          code: "",
          password: "",
          secondPassword: "",
        },
        refForm: {
          username: [{
            required: true,
            message: "请输入账号",
            trigger: "blur"
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
               var reg = /^[a-zA-Z0-9_-]{6,15}$/;
              if (!reg.test(value)) {
                callback(
                  new Error("用户名请输入6-15位")
                );
              } else {
                callback();
              }
            },
          },
          ],
          
          
           password: [
             { required: true, message: "密码不能为空", trigger: "blur" },
             {
               trigger: "blur",
               validator: (rule, value, callback) => {
                 let passwordreg =
                   /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,15}/;
                 if (!passwordreg.test(value)) {
                   callback(
                     new Error("密码必须由数字、字母、特殊字符组合,请输入6-15位")
                   );
                 } else {
                   callback();
                 }
               },
             },
           ],
           secondPassword: [
                     { required: true, message: "密码不能为空", trigger: "blur" },
            ],
           mobile: [
                     { required: true, message: "手机号不能为空", trigger: "blur" },
            ],
          code: [
                    { required: true, message: "验证码不能为空", trigger: "blur" },
           ],

        },
        show: true,
              count: "",
              timer: null,
      };
    },

    methods: {
      //验证码 倒计时
          sendVerificationCode() {
            let TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
              this.getCode();
            }
          },
            //验证码
          getCode() {
            const reg = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/
            if(!reg.test(this.loginForm.mobile)){
              this.$message({
                   type: "error",
                   message: "请输入正确的手机号码",
              });
              return
            }
            let data = {
              mobile:this.loginForm.mobile
            }
            postRequest(api.getCode, data).then(res => {
              if(res.code==0){
                setTimeout(() => {
                  this.loginForm.code = res.data.code;
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }, 3000);
                this.$message({
                     type: "success",
                     message: "验证码已发送,请查收",
                });
              }else{
                 this.$message({
                      type: "error",
                      message: res.msg,
                 });
               }

            })
          },
       //登录
           submitForm() {
             this.$refs["refForm"].validate((valid) => {
               if (valid) {
                  if(this.loginForm.password!=this.loginForm.secondPassword){
                       this.$message("两次密码验证不相等！");
                       return;
                  } 
                 postRequest(api.register, this.loginForm).then(res => {
                   if(res.code==0){
                       this.$message({
                           type: "success",
                           message: "注册成功,请前往页面登陆！",
                         });

                       this.loginForm={
                         username: "",
                         mobile: "",
                         code: "",
                         password: "",
                         secondPassword: "",
                       }
                    //   this.tologin()
                       }else{
                         this.$message({
                              type: "error",
                              message: res.msg,
                         });
                       }
                      })

                 }
             });
           },
           //登陆页面
           tologin() {
               if(!sessionStorage.getItem('isLogin')||sessionStorage.getItem('isLogin')==0){
                  // window.open(api.link_url +'/login.html')
                  window.location.href = api.link_url+'/login.html'
               }else if(sessionStorage.getItem('isLogin')==1){
                  window.location.href = api.link_url + '/manager.html'
               }
           },


    },
  };
</script>

<style lang="less" scoped>
  .login{
     background: url('../../assets/images/loginBg.png')no-repeat;
     margin:0px;
     background-size:100% 100%;
     background-attachment:fixed;
     position: relative;
  }
  .login {
    width: 100vw;
    padding: 0;
    margin: 0;
    height: 100vh;
    font-size: 16px;
    background-position: left top;
    // background-color: #242645;
    color: #fff;
    font-family: "Source Sans Pro";
    position: relative;
  }

  .mylogin {
    box-shadow: 0 0 10px #ddd;
    width:400px;
    height: 558px;


    margin:100px auto;
    padding:40px 30px;
    opacity: 1;
    background: #fff;
    border-radius: 20px;
    h4{
      font-size: 24px;
      color: #000;
      margin-bottom: 20px;
    }
  }

  .inps input {
    border: none;
    color: #fff;
    background-color: transparent;
    font-size: 12px;
  }
.unlogin{

   color: #39f;
  font-size: 14px;
  cursor: pointer;
  a{
   color: #39f;
  }
}
  .submitBtn {
    background-color: transparent;
    color: #fff;
    width: 100%;
    background-color:#39f ;
  }
  .main{
      width:1190px;
      margin: auto;
      font-size: 12px;
      text-align: left;
      position: relative;

  }

</style>
